import React from 'react';
import { hydrateRoot } from 'react-dom/client';

export { render };

function render(pageContext) {
  const { Page, initialData, initialTideData } = pageContext;
  hydrateRoot(
    document.getElementById('root'),
    <React.StrictMode>
      <Page initialData={initialData}
      initialTideData={initialTideData} />
    </React.StrictMode>
  );
}